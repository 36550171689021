import Button from 'components/UI/Button';
import { useNavigate } from 'react-router-dom';
import styles from './styles.module.scss';
import { CLOSE, ISession, OPEN, PENDING, UNSUCCESSFUL, APPROVED } from 'types/session';
import cn from "classnames";
import Request from './Request'

interface CashierData {
    cashier_login: string;
    cashier_passw: string;
}
const perPage = 10;

export default function Outsourcing() {
	const navigate = useNavigate();


	return (
		<div className={styles["section-list"]}>
            <h2>Смены аутсорс</h2>

            <h3>Плановые заявки на сегодня</h3>
            <Request status={[PENDING]} />
			
            <h3>Открытые смены</h3>
            <div className={cn(styles["section-item"], styles["section-item-top"])}>
                <Button variant="violet" onClick={() => navigate('add')}>
                    Добавить сотрудника аутсорс
                </Button> 
            </div>
            <Request status={[OPEN,CLOSE]} />
		</div>
	);
}
