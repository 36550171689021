import { useState, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import useFetch from 'use-http';
import { useUnit } from 'effector-react';
import { $coordinate, $noCoord, $token } from 'models/user';
import { registrCheckin } from 'utils/helpers';
import { $store } from 'models/store';

export function useCheckin() {
    const navigate = useNavigate();
    const { post } = useFetch();
    const { get } = useFetch(`/api/qrcode/code`);

    const token = useUnit($token);
    const noCoord = useUnit($noCoord);
    const coordinate = useUnit($coordinate);
    const store = useUnit($store);

    const [loading, setLoading] = useState(false);
    const [error, setError] = useState<string | null>(null);

    const checkin = useCallback(
        async (code: string, onAccept?: () => void) => {
            setLoading(true);
            setError(null);

            try {
                const diffGeoRes = await post('/api/checkin/diffGeo', {
                    code,
                    lat: coordinate?.latitude,
                    lon: coordinate?.longitude,
                });

                if (diffGeoRes?.statusCode) {
                    throw new Error(diffGeoRes.message || 'Ошибка при сохранении уведомления нарушения координат');
                }

                const codeData = await get(code);

                if (store?.id !== codeData?.store?.id && onAccept) {
                    onAccept();
                } else if (noCoord) {
                    navigate(`/photo/${code}`);
                } else if (!coordinate?.latitude || !coordinate?.longitude || diffGeoRes) {
                    navigate(`/maps/${code}`);
                } else {
                    await registrCheckin(post, code, token, coordinate);
                    navigate(`/`);
                }
            } catch (err) {
                if (err instanceof Error) {
                    setError(err.message);
                } else {
                    setError('Неизвестная ошибка');
                }
                console.error(err);
            } finally {
                setLoading(false);
            }
        },
        [coordinate, token, navigate, store, post, get, noCoord]
    );

    return { checkin, loading, error };
}
