import * as React from 'react'
import { Route, Routes, Outlet, Navigate, useNavigate } from 'react-router-dom'

import { useKeycloak } from '@react-keycloak/web'
import { useUnit } from 'effector-react'
import { $anotherStore, $noCoord, $token, $user, setAnotherStore, setCoordinate } from 'models/user'
import { GroupsEnum } from 'types/users'
import Login from 'components/Login';
import Home from 'pages/Home';
import useFetch, { CachePolicies, Provider } from 'use-http'
import Maps from 'pages/Maps';
import Photo from 'pages/Photo';
import Scanner from 'pages/Scanner';
import History from 'pages/History';
import AdminLayout from 'components/AdminLayout'
import Stores from 'pages/admin/Stores'
import Sessions from 'pages/admin/Sessions'
import SessionsBroken from 'pages/admin/Sessions/SessionsBroken'
import { $store, $storeList, $storeStr, setStore, setStoreList } from 'models/store'
import QRCode from 'pages/QRCode'
import Manual from 'pages/Manual'
import AcceptUser from 'pages/Manual/AcceptUser'
import PhotoUser from 'pages/Manual/PhotoUser'
import moment from 'moment'
import { usePosition } from 'hooks/usePosition'
import Layout from 'components/Layout'
import Forbidden from 'components/Forbidden'
import NotFound from 'components/NotFound'
import { IStoreItem } from 'dto/store.dto'
import Section from 'pages/Section'
import ConfirmSection from 'pages/Section/ConfirmSection'
import Outsourcing from 'pages/Outsourcing'
import AddUser from 'pages/Outsourcing/AddUser'
import PhotoOutsourceUser from 'pages/Outsourcing/PhotoUser'
import AcceptCheckin from 'pages/Outsourcing/AcceptCheckin'
import GeoError from 'components/GeoError'
import OpenShift from 'pages/Outsourcing/OpenShift'
import AnotherUser from 'pages/Outsourcing/AnotherUser'
import EditUser from 'pages/Outsourcing/EditUser'
import Accept from 'pages/Scanner/Accept'
const isMobile = /Mobi|Android/i.test(window.navigator.userAgent)
const isTDC = /DT40/i.test(window.navigator.userAgent)

moment.locale('ru');

const host = process.env.REACT_APP_BACKEND_URL;

const ProtectedRoute = ({
  isAllowed = false,
  redirectPath = `/login`,
  children,
}: {isAllowed?: boolean; redirectPath?: string; children?: React.ReactNode}) => {

  const {keycloak} = useKeycloak();
  const encodedRef = encodeURIComponent(location.pathname)
  const user = useUnit($user);
  const store = useUnit($store);
  const storeStr = useUnit($storeStr)
  const { get } = useFetch();
	const { position, error } = usePosition();
  const noCoord = useUnit($noCoord);  
  const storesList = useUnit($storeList);
  const anotherStore = useUnit($anotherStore);
  const navigate = useNavigate();

	React.useEffect(() => {
		if (position?.latitude && position?.longitude) {
			setCoordinate({latitude: position?.latitude, longitude:position?.longitude});
		}
	}, [position, setCoordinate]);

  React.useEffect(() => {
    async function loadStore() {
      if (
        user && 
        Array.isArray(user.groups) && 
        (
          user.groups.indexOf(GroupsEnum.CLIENT) > -1 ||
          user.groups.indexOf(GroupsEnum.GEN_QRCODE) > -1 ||
          user.groups.indexOf(GroupsEnum.MANUAL_REG) > -1 ||
          user.groups.indexOf(GroupsEnum.SESSION_STAT) > -1          
        )
      ) {

				try {
          // TODO refact
					let storesObject: IStoreItem[] = []
          
          if (storeStr) {      
            const correctedJsonString = storeStr.replace(/'/g, '"');
            storesObject = JSON.parse(correctedJsonString);
            if (storesObject?.length) {
              const storesList = []
              for (let i = 0; i < storesObject?.length; i++) {
                if (storesObject[i].storeId) {
                  const store = await get(`/api/stores/num/${storesObject[i].storeId}`);
                  const exist = storesList.filter((s) => s.number == store.number)
                  if (exist.length === 0) {
                    storesList.push(store)  
                  }                  
                }
              }
              setStoreList(storesList)
            }
          }
				} catch (e) {
					console.log(e)
				}
      }
    }
    if (user) {
      loadStore();
    }
  }, [user, storeStr])  

  const isAllowedPath = location.pathname === '/section' || location.pathname.startsWith('/section/confirm');

  React.useEffect(() => {
    if (!store && storesList.length === 1) {
      setStore(storesList[0])
    } else  if (!store && storesList.length > 1 && !isAllowedPath && !anotherStore) {
      navigate('/', { replace: true, state: { fromHomePage: location.pathname } });
    }
  }, [storesList, store]) 
  
  if (!store && storesList.length > 1 && !isAllowedPath && !anotherStore) {
    return <Navigate to="/section" replace state={{ fromHomePage: location.pathname }} />;
  }

  console.log('ProtectedRoute', {anotherStore, isAllowed, store, user, storesList, redirectPath, authenticated: keycloak.authenticated, encodedRef, isMobile, isTDC, if: !isMobile || isTDC, ua: window.navigator.userAgent})
  if (!user) {
    return <Navigate to={redirectPath+`?ref=${encodedRef}`} replace />;
  }
  if (!keycloak?.authenticated) {
    return <Navigate to={redirectPath+`?ref=${encodedRef}`} replace />;
  }

  if (!isAllowed) {
    if (!isMobile || isTDC) {
      return <Forbidden text="У вас недостаточно прав для использования данной функциональности. Просьба воспользоваться мобильным устройством для регистрации прихода/ухода на смену. Доступ к функциональности панели магазина и/или панели администратора предоставляется в соответствии с занимаемой должностью."/>;
    }
    return <Forbidden text={null}/>;
  }

	if ((error || !position?.latitude) && !noCoord) {
		return <GeoError message={error ? error : "Информация о местоположении недоступна."} />;
	}

  return  React.isValidElement(children) ? children : <Outlet />;
};  

const AppRouter = () => {

  const user = useUnit($user);
  const token = useUnit($token);
  const storeList = useUnit($storeList);
  const { initialized } = useKeycloak()
  const navigate = useNavigate(); 

  if (!initialized) {
    return <div>Загрузка...</div>
  }

	const globalOptions = {
		cachePolicy: CachePolicies.NO_CACHE,
		interceptors: {
			request: ({ options }: { options: RequestInit }) => {
				const newOptions = options;
				newOptions.headers = {
					Authorization: `Bearer ${token}`,
					...options.headers,
				};
				return newOptions;
			},
      response: async ({ response, request }: { response: Response; request: RequestInit }) => {
        if (!response.ok) {
          let errorMessage = 'Произошла ошибка при запросе. Попробуйте позже.';
  
          try {
            const errorData = await response.json();
            errorMessage = errorData.message || errorMessage;
          } catch (e) {
            console.error('Не удалось распарсить ответ:', e);
          }
  
          if (response.status >= 400 && response.status < 500) {
            errorMessage = errorMessage || 'Ошибка клиента. Проверьте запрос или ресурс.';
          } else if (response.status >= 500) {
            errorMessage = 'Возникли технические проблемы. Попробуйте обновить страницу или вернуться чуть позже. Если проблема сохраняется, обратитесь в Сервис Деск компании.';
          }
  
          alert(errorMessage);
  
          navigate('/');
  
          throw new Error(errorMessage);
        }
  
        return response;
      }, 
		},
	};

  return (
    <Provider url={host} options={globalOptions}>
      <Routes>        
          <Route element={
              <ProtectedRoute 
                isAllowed={isMobile && !isTDC && !!user && Array.isArray(user.groups) && user.groups.indexOf(GroupsEnum.CLIENT) > -1} 
              />
            }
          >
              <Route path="" element={<Home />} />
              <Route path="maps/:code" element={<Layout ><Maps /></Layout >} />
              <Route path="photo/:code" element={<Layout ><Photo /></Layout>} />
              <Route path="scanner/:mode" element={<Layout ><Scanner /></Layout>} />
              <Route path="scanner/:mode/accept/:qrcode" element={<Layout ><Accept /></Layout>} />
              <Route path="history" element={<History />} />          
          </Route>

          <Route element={
              <ProtectedRoute 
                isAllowed={
                  !!user && 
                  Array.isArray(user.groups) &&
                  storeList?.length > 0 &&
                  (
                    user.groups.indexOf(GroupsEnum.CLIENT) > -1 ||
                    user.groups.indexOf(GroupsEnum.GEN_QRCODE) > -1 ||
                    user.groups.indexOf(GroupsEnum.MANUAL_REG) > -1 ||
                    user.groups.indexOf(GroupsEnum.SESSION_STAT) > -1                  
                  )
                } 
              />
            }
          >
            <Route path="" element={<Layout />}>
              <Route path="qrcode"  element={<QRCode />} />            
              <Route path="section" element={<Section />} />
              <Route path="section/confirm/:storeId" element={<ConfirmSection />} />
              <Route path="manual" element={<Manual />} />
              <Route path="manual/accept/:code" element={<AcceptUser />}  />
              <Route path="manual/photo/:barcode" element={<PhotoUser />} />
            </Route>
          </Route>

          <Route element={
              <ProtectedRoute 
                isAllowed={
                  !!user && 
                  Array.isArray(user.groups) &&
                  storeList?.length > 0 &&
                  (
                    user.groups.indexOf(GroupsEnum.OUTSOURCE) > -1
                  )
                } 
              />
            }
          >
            <Route path="" element={<Layout />}>
              <Route path="outsourcing" element={<Outsourcing />} />
              <Route path="outsourcing/add" element={<AddUser />} />
              <Route path="outsourcing/edit/:outsourceId" element={<EditUser />} />
              <Route path="outsourcing/open/:sessionId" element={<OpenShift />} />
              <Route path="outsourcing/another/:sessionId" element={<AnotherUser />} />
              <Route path="outsourcing/accept/:outsourceId/:mode/:imageId?/:sessionId?" element={<AcceptCheckin />} />
              <Route path="outsourcing/photo/:outsourceId/:userId/:sessionId?" element={<PhotoOutsourceUser />} />
            </Route>
          </Route>

          <Route
              path="/admin"
              element={
                  !!user && Array.isArray(user.groups) && (user.groups.indexOf(GroupsEnum.SESSION_STAT) > -1 || user.groups.indexOf(GroupsEnum.ADMIN) > -1 ) ? (
                      <Navigate to="/admin/stores" />
                  ) : !!user && Array.isArray(user.groups) &&  user.groups.indexOf(GroupsEnum.BREACH_STAT) > -1 ? (
                      <Navigate to="/admin/broken" />
                  ) : (
                      <Navigate to="/" />
                  )
              }
          />

          <Route
            path="/admin/stores"
            element={
              <ProtectedRoute
                isAllowed={
                  !!user && 
                  Array.isArray(user.groups) && 
                  (
                    user.groups.indexOf(GroupsEnum.ADMIN) > -1 ||
                    user.groups.indexOf(GroupsEnum.SESSION_STAT) > -1
                  )
                }
              />
            }
          >  
            <Route element={<AdminLayout />  } >
              <Route path="" element={<Stores />} />
              <Route path=":storeId/sessions" element={<Sessions />} />
            </Route>   
          </Route>

          <Route
            path="/admin/broken"
            element={
              <ProtectedRoute
                isAllowed={
                  !!user && 
                  Array.isArray(user.groups) && 
                  (
                    user.groups.indexOf(GroupsEnum.ADMIN) > -1 ||
                    user.groups.indexOf(GroupsEnum.BREACH_STAT) > -1
                  )
                }
              />
            }
          >  
            <Route element={<AdminLayout />  } >
              <Route path="" element={<SessionsBroken />} />
              </Route>   
          </Route>   

          <Route path="login" element={<Login />} />
          <Route path="*" element={<NotFound />} />
      </Routes>
    </Provider>
  )
}

export default AppRouter;