import { createRoot } from "react-dom/client";
import App from './App';
import './styles.scss';
import { BrowserRouter } from 'react-router-dom';

const container: HTMLElement | null = document.getElementById('root');

if (container) {
  const root = createRoot(container);

  root.render(
    <BrowserRouter>
      <App />
    </BrowserRouter>
  );
}
