import { IStores } from './stores';
import { ICheckin } from './checkin';
import { IUser } from './users';
import { IOutsourcing } from './outsourcing';
import { IRequest } from './request';

export const UNSUCCESSFUL = 'unsuccessful' //  "Не состоялась"
export const OPEN = 'open' //  "Открыта"
export const CLOSE = 'close' //  "Закрыта"
export const PENDING = 'pending' //  "В ожидании"
export const APPROVED = 'approved' // принята исполнителем


export interface ISession {
	id: number;
	beginId: string;
	endId: string;
	userId: string;
	user: IUser;
	status: string;
	storeId: string;
	store?: IStores;
	begin: ICheckin;
	end: ICheckin;
	commentRd?: string;
	outsourcingId?: string;
	outsourcing?: IOutsourcing;
	requestId?: string;
	request?: IRequest;
	createdAt: Date;
	updatedAt: Date;
}
